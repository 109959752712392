<template>
  <v-container fluid class="pt-0" style="min-height: 100vh">
    <v-row>
      <v-col class="mt-4" cols="12" sm="6" md="3">
        <v-card class="chart-container px-3 pb-3 pt-10" height="280">
          <div class="chart-title">Склад заборгованості</div>
          <DoghnutChart :chartData="accounting_chart_data" :options="accounting_chart_options"></DoghnutChart>
        </v-card>
      </v-col>
      <v-col class="mt-4" cols="12" sm="6" md="3">
        <v-card class="chart-container px-3 pb-3 pt-10" height="280">
          <div class="chart-title">Заборгованість/Оплата</div>
          <BarChart :chartData="accounting_pays_data" :options="accounting_chart_options"></BarChart>
        </v-card>
      </v-col>
      <v-col class="mt-4" cols="12" sm="12" md="6">
        <v-card class="chart-container px-3 pb-3 pt-10" height="280">
          <div class="chart-title">Заборгованість та оплата по місяцях</div>
          <BarChart :chartData="accounting_by_year_data" :options="accounting_by_year_options"></BarChart>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card min-height="320" style="height: 100%">
          <v-card-title class="pb-1 grey--text text--darken-2">
            5 найбільших сум заборгованості
          </v-card-title>
          <v-card-text class="pa-6">
            <v-row class="grey darken-2 elevation-2 white--text rounded">
              <v-col cols="6">
                Адреса
              </v-col>
              <v-col cols="3">
                Особовий рах.
              </v-col>
              <v-col cols="3" class="text-right">
                Сума
              </v-col>
            </v-row>
            <v-row v-for="(item, idx) in flat_accounts" :key="idx">
              <v-col cols="6" class="py-2">
                {{ getAddress(item.address) }}
              </v-col>
              <v-col cols="3" class="py-2">
                {{ getPersonId(item.person_id) }}
              </v-col>
              <v-col cols="3" class="py-2 text-right font-weight-medium">
                {{ item.sum | formatToFixed }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card min-height="320" style="height: 100%">
          <v-card-title class="pb-1 grey--text text--darken-2">
            Статистика оплати
          </v-card-title>
          <v-card-text class="pa-6">
            <v-row class="success elevation-2 white--text rounded">
              <v-col cols="6">
                Банк
              </v-col>
              <v-col cols="3">
                % (к-сть)
              </v-col>
              <v-col cols="3" class="text-right">
                Сума
              </v-col>
            </v-row>
            <v-row v-for="(item, idx) in bank_accounts" :key="`bank-${idx}`">
              <v-col cols="6" class="py-2">
                {{ item.bank }}
              </v-col>
              <v-col cols="3">
                {{ `${item.percent} (${item.count})` }}
              </v-col>
              <v-col cols="3" class="py-2 text-right font-weight-medium">
                {{ item.sum | formatToFixed }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-list-item>
            <v-list-item-avatar color="success" size="70" class="mr-7">
              <v-icon size="46" dark>
                mdi-cash-100
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="card-title">Початкове сальдо</v-list-item-title>
              <v-list-item-subtitle class="card-subtitle mt-1">{{ general.start_balance | formatNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-list-item>
            <v-list-item-avatar color="success" size="70" class="mr-7">
              <v-icon size="46" dark>
                mdi-calculator-variant
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="card-title">Нараховано</v-list-item-title>
              <v-list-item-subtitle class="card-subtitle mt-1">{{ general.charge | formatNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-list-item>
            <v-list-item-avatar color="success" size="70" class="mr-7">
              <v-icon size="46" dark>
                mdi-cached
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="card-title">Перерахунок</v-list-item-title>
              <v-list-item-subtitle class="card-subtitle mt-1">{{ general.recalculation | formatNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-list-item>
            <v-list-item-avatar color="grey darken-2" size="70" class="mr-7">
              <v-icon size="46" dark>
                mdi-cash-lock
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="card-title">Монетизація</v-list-item-title>
              <v-list-item-subtitle class="card-subtitle mt-1">
                {{ general.monetary | formatNumber }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-list-item>
            <v-list-item-avatar color="grey darken-2" size="70" class="mr-7">
              <v-icon size="46" dark>
                mdi-account-cash
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="card-title">Оплачено</v-list-item-title>
              <v-list-item-subtitle class="card-subtitle mt-1">{{ general.pay | formatNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-list-item>
            <v-list-item-avatar color="grey darken-2" size="70" class="mr-7">
              <v-icon size="46" dark>
                mdi-cash-100
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="card-title">Кінцеве сальдо</v-list-item-title>
              <v-list-item-subtitle class="card-subtitle mt-1">{{ general.end_balance | formatNumber }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DoghnutChart from "@/components/charts/DoghnutChart";
import BarChart from "@/components/charts/BarChart";
import reportAPI from "@/utils/axios/reports"
import {shuffle} from 'lodash'
import {formatDate} from "@/filters";

export default {
  name: "PersonalCabinetStats.vue",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    person_hash: {
      type: String,
      default: ''
    }
  },
  components: {
    DoghnutChart,
    BarChart,
  },
  data() {
    return {
      accounting_chart_data: {
        labels: ['Утримання буд.', 'Консьєрж', 'Електроенергія'],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgb(54,235,63)'
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgb(54,235,63, 0.2)'
            ],
            data: [68888.14, 5464.88, 9994.44]
          }
        ]
      },
      colors: [
        'rgb(73,165,227)',
        'rgb(47,156,236)',
        'rgb(112,133,236)',
        'rgb(123,121,212)',
        'rgb(170,65,238)',
        'rgb(215,92,200)',
        'rgb(224,70,142)',
        'rgb(206,98,65)',
        'rgb(205,139,59)',
        'rgb(108,167,71)',
        'rgb(131,215,79)',
        'rgb(131,243,61)',
        'rgb(98,198,22)',
        'rgb(54,235,63)',
        'rgb(222,31,181)'
      ],
      accounting_chart_options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      },
      accounting_pays_data: {
        labels: ['Заборгованість', 'Оплачено'],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
            ],
            data: [123000, 188888, 0],
          }
        ]
      },
      accounting_by_year_data: {
        labels: ['Січ. 20', 'Лют. 20', 'Бер. 20', 'Квіт. 20', 'Трав. 20', 'Черв. 20'],
        datasets: [
          {
            borderWidth: 1,
            borderColor: 'rgb(54,235,63)',
            backgroundColor: 'rgb(54,235,63,0.2)',
            data: [2121.2, 423.23, 1232.99, 5656.22, 22222.22, 4464.44]
          },
          {
            borderWidth: 1,
            borderColor: 'rgb(222,31,181)',
            backgroundColor: 'rgb(222,31,181,0.2)',
            data: [1500.2, 1800.23, 300.99, 10000.22, 5000.22, 2500.44]
          }
        ]
      },
      accounting_by_year_options: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      },
      flat_accounts: [
        { address: 'м. Тернопіль, вул. Петриківська 18 кв. 10', person_id: '101010', sum: 1465.44 },
        { address: 'м. Тернопіль, вул. Данила Галицького 18 кв 55', person_id: '20201010', sum: 1000.00 },
        { address: 'м. Тернопіль, вул. Данила Галицького 10 кв 55', person_id: '30201010', sum: 999.00 },
        { address: 'м. Тернопіль, вул. Шашкевича 18 кв. 88', person_id: '40201010', sum: 600.00 },
        { address: 'м. Тернопіль, вул. Ціалковського 102 кв. 47', person_id: '40201010', sum: 500.00 },
      ],
      bank_accounts: [
        { bank: 'АТ КБ ПриватБанк', count: 10, percent: 20, sum: 1465.44 },
        { bank: 'АТ КБ Альфа-Банк', count: 10, percent: 30, sum: 1000.00 },
        { bank: 'АТ КБ Ощадбанк', count: 10, percent: 20, sum: 999.00 },
        { bank: 'АТ КБ Конокрд', count: 10, percent: 10, sum: 600.00 },
        { bank: 'АТ КБ Райффайзен Банк Аванль', count: 10, percent: 20, sum: 500.00 },
      ],
      general: {}
    }
  },
  methods: {
    getAddress(address) {
      if (this.personal) {
        if (address.indexOf('кв.')) {
          return address.substring(0, address.indexOf('кв.')) + "..."
        } else {
          return address
        }
      } else {
        return address
      }
    },
    getPersonId(person_id) {
      if (this.personal) {
        if (person_id.length <= 4) return '...'
        if (person_id.length > 4) return person_id.substring(0, 2) + '...'
      } else {
        return person_id
      }
    },
    getColors(len) {
      const colors = shuffle(this.colors.slice())
      return colors.slice(0, len)
    },
    colorsTransparent(colors, opacity) {
      const colors_transparent = []
      colors.forEach(item => {
        colors_transparent.push(item.replace(')', `, ${opacity})`))
      })
      return colors_transparent
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    changeUserData() {
      let arr = [0,0,0]
      let dat = []
      arr.forEach(() => {
        dat.push(this.getRandomInt(1, 100))
      })
      this.users_chart_data = {
        labels: ['Зареєстровано', 'Не зареєстровано', 'Heinf'],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgb(54,235,78)',
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54,235,84,0.2)',
            ],
            data: dat
          }
        ]
      }
    },
    generateAccountingCircleChart(data) {
      let accounting_chart_data = {}
      accounting_chart_data.labels = data.map(item => item.service_name)
      let colors = this.getColors(accounting_chart_data.labels.length)
      accounting_chart_data.datasets = []
      accounting_chart_data.datasets.push(
          {
            borderWidth: 1,
            borderColor: colors,
            backgroundColor: this.colorsTransparent(colors, '0.2'),
            data: data.map(item => item.sum)
          }
      )
      this.accounting_chart_data = accounting_chart_data
    },
    generateBalancePaysChart(data) {
      let accounting_pays_data = {}
      accounting_pays_data.labels = ['Заборгованість', 'Оплачено']
      let colors = this.getColors(accounting_pays_data.labels.length)
      accounting_pays_data.datasets = []
      accounting_pays_data.datasets.push(
          {
            borderWidth: 1,
            borderColor: colors,
            backgroundColor: this.colorsTransparent(colors, '0.2'),
            data: [data.balance, data.pay, 0]
          }
      )
      this.accounting_pays_data = accounting_pays_data
    },
    generateAccountingAndPaysByMonthChart(data) {
      let accounting_by_year_data = {}
      accounting_by_year_data.labels = data.map(item => formatDate(item.month, 'MMM YY'))
      accounting_by_year_data.datasets = []
      accounting_by_year_data.datasets.push(
          {
            label: 'Заборгованість',
            borderWidth: 1,
            borderColor: 'rgb(54,235,63)',
            backgroundColor: 'rgb(54,235,63,0.2)',
            data: data.map(item => item.balance)
          },
          {
            label: 'Оплата',
            borderWidth: 1,
            borderColor: 'rgb(222,31,181)',
            backgroundColor: 'rgb(222,31,181,0.2)',
            data: data.map(item => item.pay)
          }
      )
      this.accounting_by_year_data = accounting_by_year_data
    },
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          reportAPI.stats()
              .then(response => response.data)
              .then(data => {
                this.generateAccountingCircleChart(data.balance_circle)
                this.generateBalancePaysChart(data.balance_current)
                this.generateAccountingAndPaysByMonthChart(data.balance_by_month)
                this.flat_accounts = data.top_5
                this.bank_accounts = data.pays_by_banks
                this.general = data.general
              })
        }
      }
    },
  },
  created() {
    reportAPI.stats()
      .then(response => response.data)
      .then(data => {
        this.generateAccountingCircleChart(data.balance_circle)
        this.generateBalancePaysChart(data.balance_current)
        this.generateAccountingAndPaysByMonthChart(data.balance_by_month)
        this.flat_accounts = data.top_5
        this.bank_accounts = data.pays_by_banks
        this.general = data.general
      })
  }
}
</script>

<style scoped lang="scss">
  .card-title {
    color: #8c8c8c;
    font-size: .96rem;
  }
  .card-subtitle {
    font-size: 2rem;
  }
  .custom-table {
    display: flex;
    flex-direction: column;
    height: 100%;

    :deep(.v-data-table__wrapper) {
      flex: 1;
    }

    :deep(.v-data-table__footer) {
      flex: 0;
    }

  }
  .chart-container {
    position: relative;

    .chart-title {
      position: absolute;
      top: 8px;
      left: 0;
      text-align: center;
      width: 100%;
      font-weight: 500;
    }

    > div:not(.chart-title) {
      position: relative;
      height: 100%;
    }
  }

</style>